<template>
    <div id="amapcontainer" style="width: 500px; height: 500px; margin-left: 60px;"></div>
  </template>
  
  <script>
  import AMapLoader from '@amap/amap-jsapi-loader';
  window._AMapSecurityConfig = {
    securityJsCode: '170a9e3415dddbc279830fc7772a1bed' // '「申请的安全密钥」',
  }
  export default {
    props: {
         acc:Object,
       },
    data () {
      return {
        map: null,
        Lngi:116.397418,
        Lati:39.909221
      }
    },
    mounted () {
      //DOM初始化完成进行地图初始化
      this.initAMap()
    },
    methods: {
      initAMap () {
        var that = this
        AMapLoader.load({
          key: "e673c0b7bc771235f84001d96bc4696f", // 申请好的Web端开发者Key，首次调用 load 时必填
          version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
          plugins: ["AMap.Scale", "AMap.ToolBar", "AMap.ControlBar", 'AMap.Geocoder', 'AMap.Marker',
            'AMap.CitySearch', 'AMap.Geolocation', 'AMap.AutoComplete', 'AMap.InfoWindow'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        }).then((AMap) => {
          // 获取到作为地图容器的DOM元素，创建地图实例
          this.map = new AMap.Map("amapcontainer", { //设置地图容器id
            resizeEnable: true,
            zoom: this.zoom, // 地图显示的缩放级别
            viewMode: "3D", // 使用3D视图
            zoomEnable: true, // 地图是否可缩放，默认值为true
            dragEnable: true, // 地图是否可通过鼠标拖拽平移，默认为true
            doubleClickZoom: true, // 地图是否可通过双击鼠标放大地图，默认为true
            zoom: 14, //初始化地图级别
            // center: [114.519425,36.626457], //
            center: [that.Lngi,that.Lati], 
            // mapStyle: "amap://styles/darkblue", // 设置颜色底层
          })
          // this.setMapMarker()
          this.marker = new AMap.Marker({
                    icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
                    position: [that.Lngi,that.Lati], 
                    offset: new AMap.Pixel(-13, -30)
                });
          this.marker.setMap(this.map);
                // 生成一组在西安市的随机点经纬度数组
                // this.randomMarker = new Array(500).fill(true).map(() => {
                //     return [108.939645 + Math.random() * 0.1, 34.343207 + Math.random() * 0.1]
                // })
                // 处理经纬度数组，经纬度要保留6位小数
                // this.randomMarker = this.randomMarker.map((item) => {
                //     return [Number(item[0].toFixed(6)), Number(item[1].toFixed(6))]
                // })
                // 添加比例尺控件
                // this.map.addControl(new AMap.Scale())
                // 添加缩放控件
                // this.map.addControl(new AMap.ToolBar())
                // console.log('随机点数组', this.randomMarker)
                // 监听地图移动事件，实现覆盖物懒加载
                this.map.on("click", this.lazyLoadMarkers);
                
        
        }).catch(e => {
            // console.log(e)
          })
      },

        // 懒加载函数，根据可见区域范围判断哪些覆盖物需要展示
        lazyLoadMarkers(e) {
          this.Lngi = e.lnglat.lng 
          this.Lati = e.lnglat.lat
          this.initAMap();
        }, 
    },
//    setMapMarker(){
//   let icon = new AMap.Icon({
//     size:new AMap.Size(36,36),
//     image:'',
//     imageSize: new AMap.Size(24,30),
//     imageOffset:new AMap.Pixel(0,0)
//   });
//   let makerList = [];
//    this.mapList.forEach((item) =>{
//        let marker =new AMap.Marker({
//          map:this.map,
//          zIndex:9999999,
//          icon:'https://img0.baidu.com/it/u=2020518972,2077284106&fm=253&app=120&size=w931&n=0&f=JPEG&fmt=auto?sec=1701190800&t=67cd0e04a5554c8d63b78d63f367d44a',
//          offset: new AMap.pixel(-13,-30),
//          position: item.lnglats
//        });
//        makerList.push(marker)
//   } )
//   this.map.add(makerList);
//   this.map.setFitview();
// }

  }
  </script>
  
  <style lang="less">
  </style>
  