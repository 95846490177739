import { store } from '@/store'
import { RouterView, RouteRecordRaw } from "vue-router";

const menuRule: RouteRecordRaw[] = []
// console.log("```", store.state.menus)
const routes = store.state.menus
for (const key in routes) {
  const children: RouteRecordRaw[] = []
  const comp = routes[key].children
  if (comp) {
    for (const key1 in comp) {
      children.push({
        path: comp[key1].path,
        component: () => import(`@/views/${comp[key1].component}`),
        meta: {
          title: comp[key1].title
        },
      })
    }
  } else {
    children.push({
      path: routes[key].path,
      component: () => import(`@/views/${routes[key].component}`),
    })
  }
  menuRule.push({
    path: routes[key].path,
    component: RouterView,
    meta: {
      title: routes[key].title
    },
    children: children
  })
}
export default menuRule


