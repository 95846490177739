import { createRouter, createWebHashHistory, RouteRecordRaw, RouterView } from 'vue-router'
import AppLayout from '@/layout/AppLayout.vue'
import nprogress from 'nprogress'
import storeRouter from './moudules/store'
import 'nprogress/nprogress.css'
import { store } from '@/store'
import menuRule from './routers'
const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: AppLayout,
    meta: {
      requiresAuth: true
    },
    children: menuRule,
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue'),
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.beforeEach((to, from) => {
  nprogress.start() // 开始加载进度条
  // console.log(store.state.menus)
  if (to.meta.requiresAuth && !store.state.user) {
    // 此路由需要授权，请检查是否已登录
    // 如果没有，则重定向到登录页面
    return {
      path: '/login',
      // 保存我们所在的位置，以便以后再来
      query: { redirect: to.fullPath }
    }
  }
})

router.afterEach(() => {
  if (localStorage.getItem("isFirst") == "true") {
    localStorage.setItem("isFirst", "false")
    location.reload();
  }
  nprogress.done() // 加载进度条
  // console.log('sfdfdsfds')
  // console.log(store.state.menus)
})

export default router
